import React, { useState, useRef, useEffect } from 'react';
import { Modal, Input, Button, message } from 'antd';
import { UserOutlined, RobotOutlined, SendOutlined } from '@ant-design/icons';
import {  CloseOutlined } from '@ant-design/icons';
import { get } from 'lodash';

function NotesChat({msg,setChat,activeTab}) {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const messagesEndRef = useRef(null);
   
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  function getName(tab){
switch (tab) {
        case "history":
            return "HNP Notes";
        case "progress":
            return "Progress Notes";
        case "consultation":
            return "Consultations Notes";
        case "treatment":
            return "Treatment Notes";
        case "goals":
            return "Goals Notes";
        case "physicalTherapy":
            return "Physical Therapy Notes";
        case "rehabilitation":
            return "Rehabilitation Therapy Notes";
        case "occupational":
            return "Occupational Therapy Notes";
        case "speech":
            return "Speech Therapy Notes";
        default:
            return "History";
      }
  }

  const streamMessage = async (content, key, onProgress) => {
    try {
      const response = await fetch(
        `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=${key}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            system_instruction: {
    parts:
      { "text": msg}},
   
            contents: [
              {
                parts: [{ text: content }],
              },
            ],
            safetySettings: [
              {
                category: 'HARM_CATEGORY_DANGEROUS_CONTENT',
                threshold: 'BLOCK_ONLY_HIGH',
              },
            ],
            generationConfig: {
              temperature: 1.0,
              maxOutputTokens: 800,
              topP: 0.8,
              topK: 10,
            },
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const text = data?.candidates?.[0]?.content?.parts?.[0]?.text || '';

      if (!text) {
        throw new Error('No text in response');
      }

      const words = text.split(' ');
      let fullText = '';

      for (const word of words) {
        fullText += `${word} `;
        onProgress(fullText);
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
    } catch (error) {
      console.error('Error streaming message:', error);
      throw error;
    }
  };

  const handleSendMessage = async (content) => {
   

    const userMessage = { role: 'user', content };
    const assistantMessage = {
      role: 'assistant',
      content: '',
      isStreaming: true,
    };

    setMessages((prev) => [...prev, userMessage, assistantMessage]);
    setIsLoading(true);

    try {
      await streamMessage(content, apiKey, (progress) => {
        setMessages((prev) => {
          const newMessages = [...prev];
          newMessages[newMessages.length - 1] = {
            role: 'assistant',
            content: progress,
            isStreaming: true,
          };
          return newMessages;
        });
      });

      setMessages((prev) => {
        const newMessages = [...prev];
        newMessages[newMessages.length - 1] = {
          role: 'assistant',
          content: newMessages[newMessages.length - 1].content,
          isStreaming: false,
        };
        return newMessages;
      });
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to send message. Please check your API key and try again.');
      setMessages((prev) => prev.slice(0, -1));
    } finally {
      setIsLoading(false);
    }
  };


  
    const ChatMessage = ({ message }) => {
      const isUser = message.role === 'user';
  
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: isUser ? 'row-reverse' : 'row',
            alignItems: 'flex-start',
            gap: '1rem',
          }}
        >
          <div
            style={{
              padding: '0.5rem',
              borderRadius: '50%',
              backgroundColor: isUser ? '#4299e1' : '#718096',
            }}
          >
            {isUser ? (
              <UserOutlined style={{ color: 'white' }} />
            ) : (
              <RobotOutlined style={{ color: 'white' }} />
            )}
          </div>
          <div
            style={{
              flex: 1,
              padding: '0.5rem 1rem',
              borderRadius: '0.5rem',
              backgroundColor: isUser ? '#ebf8ff' : '#f7fafc',
            }}
          >
            <p style={{ color: '#2d3748', whiteSpace: 'pre-wrap' }}>
              {message.content}
              {message.isStreaming && (
                <span
                  style={{
                    display: 'inline-block',
                    width: '0.5rem',
                    height: '1rem',
                    marginLeft: '0.25rem',
                    backgroundColor: '#718096',
                    animation: 'pulse 1.5s infinite',
                  }}
                />
              )}
            </p>
          </div>
        </div>
      );
    };
  
    const ChatInput = ({ onSendMessage, disabled }) => {
      const [message, setMessage] = useState('');
  
      const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim() && !disabled) {
          onSendMessage(message);
          setMessage('');
        }
      };
  
      return (
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            gap: '0.5rem',
          }}
        >
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your question..."
            disabled={disabled}
            style={{ flex: 1 }}
          />
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabled || !message.trim()}
            icon={<SendOutlined />}
          />
        </form>
      );
    };
  
    return (
      <div
        style={{
          minHeight: '50vh',
          width:"500px",
         
          display: 'flex',
          position:"absolute",
          right:"0",
          bottom:"0",
          zIndex:"100",
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
     
  
        <div
          style={{
            maxWidth: '32rem',
            width: '70%',
            height:"500px",
            backgroundColor: 'white',
            borderRadius: '0.5rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
          }}
        >
         <div
  style={{
    backgroundColor: '#4299e1',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    position: 'relative', // For positioning the close icon
  }}
>
  <RobotOutlined style={{ color: 'white', fontSize: '1.5rem' }} />
  <h1 style={{ fontSize: '0.7rem', color: 'white' }}>
    AI Chat with ({getName(activeTab)})
  </h1>
  <CloseOutlined
    onClick={() => { 
        setChat(null)
     }}
    style={{
      color: 'white',
      fontSize: '1.5rem',
      position: 'absolute',
      top: '50%',
      right: '1rem',
      transform: 'translateY(-50%)', // Vertically center the close icon
      cursor: 'pointer',
    }}
  />
</div>
  
          <div
            style={{
              height: '45vh',
              overflowY: 'auto',
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            {messages.map((message, index) => (
              <ChatMessage key={index} message={message} />
            ))}
            <div ref={messagesEndRef} />
          </div>
  
          <div style={{ padding: '1rem', borderTop: '1px solid #e2e8f0' }}>
            <ChatInput onSendMessage={handleSendMessage} disabled={isLoading} />
          </div>
        </div>
      </div>
    );
  }
  
  export default NotesChat;
  