import Chart from "react-apexcharts";
export const OrderStatusChart = ({ data }) => {
    const options = {
      chart: {
        fontFamily: "inherit",
        type: 'area',
        height: 350,
        stacked: true,
       
         // Enable stacking
      },
      xaxis: {
        axisBorder: {
            show: false,
            color: "#ccc",
          },
          axisTicks: {
            show: false,
            color: "#ccc",
          },
        //  categories: ""
        categories: data.dates,
      },
     
     
      dataLabels: {
        enabled: true,
      },
     
      
      grid: {
      
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    
    };
    const series = [
      {
        name: 'Pending',
        data: data.pending,
      },
      {
        name: 'Accept',
        data: data.accept,
      },
      {
        name: 'Reject',
        data: data.reject,
      },
    ];
  
    return (
        <div
          style={{
            boxShadow: "0px 10px 15px #dddddd",
            margin: "20px 10px",
            padding: "10px",
          }}
        >
          <div style={{ display: "flex", margin: "3px 15px" }}>
            <span style={{ fontWeight: "500", fontSize: '20px' }}>
              {"Order Status Over Time"} 
            </span>
          </div>
          <Chart options={options} series={series} type="area" height={350} />
        </div>
      );
  };