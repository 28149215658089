


// import { useEffect, useState, useCallback } from "react";
// import io from "socket.io-client";
// import { chatURL } from "../../../config/socketConfig";
// import { useDispatch, useSelector } from "react-redux";
// import { setCurrentChat } from "../../../store/chat/Slice";

// import {
//   MainContainer,
//   ChatContainer,
//   MessageList,
//   Message,
//   MessageInput,
//   ConversationHeader,
// } from "@chatscope/chat-ui-kit-react";




// import Draggable from "react-draggable";
// import { MdClose } from "react-icons/md";
// import { Avatar } from "antd";

// const ChatUICaseManager = () => {
//   const dispatch = useDispatch();
//   const { user, currentChat } = useSelector(({ caseManagerSlice, user, chat }) => ({
//     ...caseManagerSlice,
//     ...user,
//     ...chat,
//   }));

//   const [socket, setSocket] = useState(null); // Maintain socket state
//   const [roomId, setRoomId] = useState(null);
//   const [messages, setMessages] = useState([]);

//   // Handle connection on mount
//   useEffect(() => {
//     const newSocket = io.connect(chatURL()); // Create new socket connection
//     setSocket(newSocket);

//     // On component unmount, disconnect the socket
//     return () => {
//       if (newSocket) {
//         newSocket.disconnect();
//       }
//     };
//   }, []); // This ensures socket is initialized once

//   // Function to handle socket disconnection
//   const disconnectSocket = useCallback(() => {
//     if (socket) {
//       socket.disconnect();
//       console.log("Socket disconnected:", socket.connected);
//     }
//   }, [socket]);

//   // Function to handle socket connection
//   const connectSocket = useCallback(() => {
//     if (socket && !socket.connected) {
//       socket.connect();
//       console.log("Socket connected:", socket.connected);
//     }
//   }, [socket]);

//   // Setup message handling and room history retrieval
//   // const receiveMessage = useCallback(() => {
//   //   if (socket) {
//   //     socket.on("chatmsgPatient", (arg) => {
//   //       const newMessage = {
//   //         message: arg?.body?.data,
//   //         is_read: 1,
//   //         from: Number(user?.id),
//   //         to: arg?.body?.to_user,
//   //         created_at: new Date(),
//   //         sender: arg?.body?.user,
//   //       };
//   //       setMessages((prev) => [...prev, newMessage]);
//   //     });
//   //   }
//   // }, [socket, user?.id]);

//   const receiveMessage = useCallback(() => {
//     if (socket) {
//       socket.off("chatmsgPatient"); // Remove previous listener if exists
//       socket.on("chatmsgPatient", (arg) => {
//         const newMessage = {
//           message: arg?.body?.data,
//           is_read: 1,
//           from: Number(user?.id),
//           to: arg?.body?.to_user,
//           created_at: new Date(),
//           sender: arg?.body?.user,
//         };
//         setMessages((prev) => [...prev, newMessage]);
//       });
//     }
//   }, [socket, user?.id]);
  

//   const getChatHistory = useCallback(
//     (rId) => {
//       if (socket) {
//         socket.emit("cm_get_room_history_patient", {
//           room: rId || roomId,
//           user: user?.id,
//           meta: {
//             room: rId || roomId,
//             current_user: {
//               user_id: user?.id,
//               name: user?.username,
//             },
//             to_user: {
//               user_id:"0",
//             },
//             context: "cs_to_sp",
//           },
//         });

//         socket.on("cm_get_room_history_patient", (data) => {
//           console.log("message",data);
//           setMessages(data ?? []);
//         });
//       }
//     },
//     [socket, "0", roomId, user?.id, user?.username]
//   );

//   const addToRoom = useCallback(
//     (roomid) => {
//       if (socket) {
//         socket.emit("addToRoomPatient", {
//           room: roomid || roomId,
//           user: user?.id,
//           meta: {
//             referralId:currentChat?.vendorid,
//             current_user: {
//               user_id: user?.id,
//               name: user?.username,
//             },
//             to_user: {
//               user_id: "0",
//             },
//             context: "cs_to_sp",
//           },
//         });

//         getChatHistory(roomid || roomId);
//       }
//     },
//     [socket, "0", getChatHistory, roomId, user?.id, user?.username]
//   );

//   const createRoom = useCallback(() => {
//     let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//     let roomid = "";

//     for (let i = 0; i < 10; i++) {
//       roomid += possible.charAt(Math.floor(Math.random() * possible.length));
//     }

//     setRoomId(roomid);
//     addToRoom(roomid);
//   }, [addToRoom]);
//   const onClose = () => {
//     dispatch(setCurrentChat(null));
//     disconnectSocket();
//   };
//   const getRoomList = useCallback(() => {
//     if (!socket) return;

//     const obj = {
//      // user: user?.id,
//       meta: {
//         referralId:currentChat?.vendorid,
//         // current_user: {
//         //   user_id: user?.id,
//         //   name: user?.username,
//         // },
//         // to_user: {
//         //   user_id: currentChat?.vendorid,
//         // },
//         context: "list_of_rooms",
//       },
//     };

     
    
//     socket.emit("get_room_list_patient", obj);
//     socket.on("get_room_list_patient", (data) => {
//       if (data?.length === 0) {
//         createRoom();
//       } else {
//         console.log("room data: " + JSON.stringify(data));
//         setRoomId(data?.[0]);
//         addToRoom(data?.[0]);
//       }
//     });
//   }, [socket, "0", createRoom, user?.id, user?.username]);
//   useEffect(() => {
//     if (socket) {
//       getRoomList();
//       receiveMessage();
//     }
//   }, [socket]); // Removed extra dependencies
  

//   // useEffect(() => {
//   //   if (socket) {
//   //     getRoomList();
//   //     receiveMessage();
//   //   }
//   // }, [socket, getRoomList, receiveMessage]);
//   const onMsgSend = (m) => {
//     console.log("Sending message:", m); // Debugging log
//     const chatData = {
//       data: m,
//       user: user?.id,
//       meta: {
//         room: roomId,
//         current_user: {
//           user_id: user?.id,
//           name: user?.username,
//         },
//         to_user: {
//           user_id: "0",
//         },
//         context: "cs_to_sp",
//       },
//     };
  
//     socket.emit("chatmsgPatient", chatData);
  
//     setMessages((p) => [
//       ...p,
//       {
//         chat_room_id: roomId,
//         message: m,
//         is_read: 1,
//         from: Number(user?.id),
//         to: "0",
//         created_at: new Date(),
//         sender: user?.username,
//       },
//     ]);
//   };
  
//   // const onMsgSend = (m) => {
//   //   const chatData = {
//   //     data: m,
//   //     user: user?.id,
//   //     meta: {
//   //       room: roomId,
//   //       current_user: {
//   //         user_id: user?.id,
//   //         name: user?.username,
//   //       },
//   //       to_user: {
//   //         user_id: "0",
//   //       },
//   //       context: "cs_to_sp",
//   //     },
//   //   };

//   //   socket.emit("chatmsgPatient", chatData);

//   //   setMessages((p) => [
//   //     ...p,
//   //     {
//   //       chat_room_id: roomId,
//   //       message: m,
//   //       is_read: 1,
//   //       from: Number(user?.id),
//   //       to: "0",
//   //       created_at: new Date(),
//   //       sender: user?.username,
//   //     },
//   //   ]);
//   // };
//   return (
//     <Draggable>
//       <div className="float-chat-wrapper">
//         <div style={{ position: "relative", height: "100%" }}>
//           <MdClose
//             size={26}
//             className="position-absolute cursor-pointer"
//             style={{ top: 20, right: 10, zIndex: 9 }}
//             onClick={onClose}
//           />
//           <MainContainer>
//             <ChatContainer>
//               <ConversationHeader className="position-relative">
//                 <Avatar name={currentChat?.vendorname} />
//                 <ConversationHeader.Content
//                   userName={currentChat?.vendorname || "Case Manager"}
//                   info=""
//                 />
//               </ConversationHeader>
//               <MessageList autoScrollToBottomOnMount={true}>
//                 {messages?.map((el) => (
//                   <Message
//                     model={{
//                       message: el?.message,
//                       sentTime: el?.created_at,
//                       sender: el?.sender,
//                       direction:
//                         el?.sender === user?.username
//                           ? "outgoing"
//                           : "incoming",
//                     }}
//                   />

//                 ))}
//               </MessageList>
//               <MessageInput
//                 style={{ minHeight: 70 }}
//                 className="d-flex align-items-center"
//                 attachButton={false}
//                 fancyScroll
//                 placeholder="Type message here..."
//                 onSend={onMsgSend}
//               />
//             </ChatContainer>
//           </MainContainer>
//         </div>
//       </div>
//     </Draggable>
//   );
// };

// export default ChatUICaseManager;

import React, { useEffect, useState, useCallback } from "react";
import io from "socket.io-client";
import { chatURL } from "../../../config/socketConfig";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChat } from "../../../store/chat/Slice";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  ConversationHeader,
} from "@chatscope/chat-ui-kit-react";

import Draggable from "react-draggable";
import { MdClose } from "react-icons/md";
import { Avatar } from "antd";
import moment from "moment";

const ChatUICaseManager = () => {
  const dispatch = useDispatch();
  const { user, currentChat } = useSelector(({ caseManagerSlice, user, chat }) => ({
    ...caseManagerSlice,
    ...user,
    ...chat,
  }));

  const [socket, setSocket] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const newSocket = io.connect(chatURL());
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const receiveMessage = useCallback(() => {
    if (socket) {
      socket.off("chatmsgPatient");
      socket.on("chatmsgPatient", (arg) => {
        console.log(arg);
        const newMessage = {
          message: arg?.body?.data,
          is_read: 1,
          from: Number(user?.id),
          to: arg?.body?.to_user,
          created_at: new Date(),
          sender: arg?.body?.meta?.current_user?.name,
        };
        setMessages((prev) => [...prev, newMessage]);
      });
    }
  }, [socket, user?.id]);

  const getChatHistory = useCallback((rId) => {
    if (socket) {
      socket.emit("cm_get_room_history_patient", {
        room: rId || roomId,
        user: user?.id,
        meta: {
          room: rId || roomId,
          current_user: { user_id: user?.id, name: user?.username },
          to_user: { user_id: "0" },
          context: "cs_to_sp",
        },
      });
      
      socket.off("cm_get_room_history_patient");
      socket.on("cm_get_room_history_patient", (data) => {
        console.log(data);
       // console.log(messages)
        setMessages(data ?? []);
      });
    }
  }, [socket, roomId, user?.id, user?.username]);

  const addToRoom = useCallback((roomid) => {
    if (socket) {
      socket.emit("addToRoomPatient", {
        room: roomid || roomId,
        user: user?.id,
        meta: {
          referralId: currentChat?.vendorid,
          current_user: { user_id: user?.id, name: user?.username },
          to_user: { user_id: "0" },
          context: "cs_to_sp",
        },
      });
      getChatHistory(roomid || roomId);
    }
  }, [socket, currentChat, getChatHistory, roomId, user?.id, user?.username]);

  const createRoom = useCallback(() => {
    const roomid = Array(10)
      .fill(0)
      .map(() => "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".charAt(Math.floor(Math.random() * 62)))
      .join("");
    setRoomId(roomid);
    addToRoom(roomid);
  }, [addToRoom]);

  const getRoomList = useCallback(() => {
    if (!socket) return;

    const obj = { meta: { referralId: currentChat?.vendorid, context: "list_of_rooms" } };

    socket.emit("get_room_list_patient", obj);
    socket.off("get_room_list_patient");
    socket.on("get_room_list_patient", (data) => {
      if (data?.length === 0) {
        createRoom();
      } else {
        setRoomId(data[0]);
        addToRoom(data[0]);
      }
    });
  }, [socket, currentChat, createRoom]);

  useEffect(() => {
    if (socket) {
      getRoomList();
      receiveMessage();
    }
  }, [socket, getRoomList, receiveMessage]);

  const onMsgSend = (m) => {
    const chatData = {
      data: m,
      user: user?.id,
      meta: {
        room: roomId,
        current_user: { user_id: user?.id, name: user?.username },
        to_user: { user_id: "0" },
        context: "cs_to_sp",
      },
    };
    socket.emit("chatmsgPatient", chatData);

    setMessages((prev) => [
      ...prev,
      { chat_room_id: roomId, message: m, is_read: 1, from: Number(user?.id), to: "0", created_at: new Date(), sender: user?.username },
    ]);
  };

  return (
    <Draggable>
      <div className="float-chat-wrapper">
        <div style={{ position: "relative", height: "100%" }}>
          <MdClose size={26} className="position-absolute cursor-pointer" style={{ top: 20, right: 10, zIndex: 9 }} onClick={() => {
            dispatch(setCurrentChat(null));
            if (socket) socket.disconnect();
          }} />
          <MainContainer>
            <ChatContainer>
              <ConversationHeader className="position-relative">
                <Avatar name={currentChat?.vendorid} />
                <ConversationHeader.Content userName={"Chat Referral #"+currentChat?.vendorid || "Case Manager"} info="" />
              </ConversationHeader>
              <MessageList autoScrollToBottomOnMount={true}>
                {messages.map((el, index) => (
                   <Message
                   key={index}
                   message={el.message}
                   sender={el.sender}
                   sentTime={el.created_at || ""}
                   isCurrentUser={el.sender === user.username}
                 />
                  // <Message
                  //   key={index}
                  //   model={{
                  //     message: el.message,
                      
                  //     sentTime: el.created_at,
                  //     sender: el.sender,
                  //     direction: el.sender === user?.username ? "outgoing" : "incoming",
                  //   }}
                    
                  // />
                ))}
              </MessageList>
              <MessageInput
                style={{ minHeight: 70 }}
                className="d-flex align-items-center"
                attachButton={false}
                fancyScroll
                placeholder="Type message here..."
                onSend={onMsgSend}
              />
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
    </Draggable>
  );
};

export default ChatUICaseManager;


const Message = ({ message, sender, sentTime, isCurrentUser }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: isCurrentUser ? "flex-end" : "flex-start",
        marginBottom: "10px",
      }}
    >
      {/* Display Sender Name */}
      <div style={{ fontWeight: "bold", color: isCurrentUser ? "#007bff" : "#333" ,fontSize:"12px"}}>
        {sender}
      </div>

      {/* Display Message Content */}
      <div
        style={{
          maxWidth: "60%",
          padding: "10px",
          borderRadius: "8px",
          backgroundColor: isCurrentUser ? "#d1e7ff" : "#f1f1f1",
          color: "#333",
          textAlign: "left",
        }}
      >
        {message}
      </div>

      {/* Display Sent Time */}
      <div style={{ fontSize: "12px", color: "#888", marginTop: "5px" }}>
        {sentTime && sentTime != null && sentTime !== undefined && sentTime !== "" && moment(sentTime).isValid()
                ? moment(sentTime).format("D MMM, h:mm A")
                : ""}
      </div>
    </div>
  );
};