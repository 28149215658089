import React, { useEffect, useState } from "react";
import { Button, Drawer, message, Form, Row, Col, Select } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { useSelector } from "react-redux";
import { FiUploadCloud } from "react-icons/fi";
import AttachmentCard from "../cards/AttachmentCard";
import { postApi } from "../../../store/AddService/service";
import AttachmentPreview from "./AttachmentPreview";
import { isMobile } from "react-device-detect";

const initialValues = {
  prescriptions: [],
};

const UpdateOrder = ({ data, reloadData, onClose, isProviderReffer }) => {
  const [form] = Form.useForm();

  const { user, token } = useSelector(({ user, caseManagerSlice }) => ({
    ...caseManagerSlice,
    ...user,
  }));
  const [selected, setSelected] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
 
  useEffect(() => {
    if (isProviderReffer && isProviderReffer === true) {
      const attachmentIds = (data?.attachmentlink ?? []).map((el) => el?.id);

      setSelected(attachmentIds);
    } else {
      const attachmentIds = (data?.pharmacyinfo?.prescription_image ?? []).map(
        (el) => el?.id
      );
      if(data?.accept_status === "2" || data?.accept_status === "5")
      getAgencyList();

      setSelected(attachmentIds);
    }
  }, []);
  const [vendors, setVendors] = useState([]);
  
  const getAgencyList = async () => {
    try {
      const FD = new FormData();
      FD.append("patientId", data?.user_id);
      FD.append("auterizetokenid", user?.id);
      FD.append("memberId", 0);
    FD.append("areacode", data.isfacility === "1"?user?.areacode:"0");
      FD.append("zipcode", data?.zipcode || "");
      FD.append("action", "get_vendor_list_by_zip");
      FD.append("insuranceId", data?.insuranceId || "");
      FD.append("category_id", data?.category_id || "");

    //  console.log('service detail', serviceDetail);
       
      const res = await postApi(FD, token);
      if (res?.status === 200) {
        setVendors(res?.data?.vendors ?? []);
      }
    } catch (error) {
      setVendors([]);
    }
  };

  const updateOrder = async (payload) => {
    setSubmitting(true);
    try {
      const { orderId, attachmentIds, prescriptions,vendorid } = payload;
       
      const FD = new FormData();
      FD.append("action", "update_patient_order");
      FD.append("orderId", orderId);
      FD.append("userId", user?.id);
      FD.append("auterizetokenid", user?.id);
      FD.append("vendorid",data?.accept_status === "2" || data?.accept_status === "5"? vendorid || "":"");
      
      (prescriptions ?? []).forEach((i) =>
        FD.append("prescriptions[]", i?.originFileObj, i.name)
      );
      FD.append("attachmentIds", attachmentIds);
      const res = await postApi(FD, token);
      if (res?.status === 200) {
        setSubmitting(false);
        message.success("Order updated successfully");
        if (reloadData) {
          reloadData();
        }
        onClose();
      }
    } catch (error) {
      setSubmitting(false);
      message.error("Failed to update the order");
    }
  };

  const updateOrderProviderRefferal = async (payload) => {
    setSubmitting(true);
    try {
      const { referralId, attachmentid, prescriptions } = payload;

      const FD = new FormData();
      FD.append("action", "update_submitted_doctor_referral");
      FD.append("referralId", referralId);
      FD.append("auterizetokenid", user?.id);

      (prescriptions ?? []).forEach((i) =>
        FD.append("prescriptions[]", i?.originFileObj, i.name)
      );
      FD.append("attachmentid", attachmentid);
      const res = await postApi(FD, token);
      if (res?.status === 200) {
        setSubmitting(false);
        message.success("Referral updated successfully");
        if (reloadData) {
          reloadData();
        }
        onClose();
      }
    } catch (error) {
      setSubmitting(false);
      message.error("Failed to update the order");
    }
  };
  const onFinish = (v) => {
    if (isProviderReffer && isProviderReffer === true) {
      const p = {
        referralId: data?.refid,
        attachmentid: (selected ?? []).join(","),
        prescriptions: v?.prescriptions?.fileList ?? [],
       
      };
      updateOrderProviderRefferal(p);
    } else {
     
      const p = {
        orderId: data?.order_id,
        attachmentIds: (selected ?? []).join(","),
        prescriptions: v?.prescriptions?.fileList ?? [],
        vendorid: v?.vendorid || "",
      };
      updateOrder(p);
    }
  };

 

  const handleChange = (info) => {
    let newFileList = [...info.fileList];
  
    // Filter out unsupported file types
    newFileList = newFileList.filter((file) => {
      const isSupportedFileType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "application/pdf" ||
        file.type === "application/msword" || // for .doc files
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  
      if (!isSupportedFileType) {
        message.error(`${file.name} is not a supported file type.`);
        return false; // Filter out unsupported files
      }
      const isValidSize = file.size / 1024 / 1024 < 10; // Convert size from bytes to MB and check

      if (!isValidSize) {
      //  setUploadError(true);
        message.error("File size must be smaller than 10 MB!");
        return false;
      }
  
      return true;
    });
  
    // Map through the fileList to handle file response for uploaded files
    newFileList = newFileList.map((file) => {
      if (file.response) {
        // If file is uploaded, add the URL
        file.url = file.response.url;
      }
      return file;
    });
  
    // Update the state with the new filtered fileList
    setFileList(newFileList);
  };
  

  const handleAttachmentSelect = (id) => {
    if (selected?.includes(id)) {
      const v = selected.filter((e) => e !== id);
      setSelected(v);
    } else {
      setSelected([...selected, id]);
    }
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    onChange: handleChange,
    multiple: true,
    fileList,
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "application/pdf" ||
      file.type === "application/msword" || // for .doc files
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";;

    // || file.type ===
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/doc file!");
      return false;
    }
    setFileList([...fileList, file]);
    

    return false;
  };


    const openInNewTab = (url) => {
      window.open(`https://docs.google.com/gview?url=${url}&embedded=true`, '_blank');
      //const newWindow = window.open(`https://docs.google.com/gview?url=${url}&embedded=true`, '_blank', 'noopener,noreferrer')
      //if (newWindow) newWindow.opener = null
    }
  
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const onPreview = (data) => {
     if(data?.image.includes("doc")){

openInNewTab(data?.image)
     }else{
    setPreviewOpen(true);
    setPreviewImage({
      type: data?.image.includes("pdf") ? "application/pdf" : "image",
      url: data?.image,
      // title:data?.image?.substring(data?.image.lastIndexOf("/") + 1)
    });
  }
  }
    // setPreviewTitle(
    //   file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    // );
  ;
  const handleCancel = () => {
    setPreviewOpen(false);
    setPreviewImage(null);
  };

  const btnWidth = isMobile ? "w-30" : "w-25";

  return (
    <Drawer
      open
      width={isMobile ? "80%" : 575}
      placement="right"
      onClose={onClose}
      title={<div>Update Order</div>}
      footer={
        <div className="d-flex align-items-center justify-content-end">
          <Button
            type="default"
            className={`mb-0  ${btnWidth} mr-2`}
            onClick={onClose}
            disabled={isSubmitting}
          >
            cancel
          </Button>
          <Button
            loading={isSubmitting}
            type="primary"
            onClick={form.submit}
            className={`mb-0 ${btnWidth}`}
          >
            Update
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValues}
      >
        <div className="mt-3 pt-2 d-flex justify-content-start">
          <Form.Item
            // getValueFromEvent={getFile}
            className="w-100 mb-5"
            name="prescriptions"
            rules={[
              {
                required: false,
                message: "please upload at least one file",
              },
            ]}
          >
            <Dragger
              listType="picture"
              beforeUpload={beforeUpload}
              // onPreview={handlePreview}
              {...props}
              maxCount={15}
              multiple
              className="p-3 w-100"
            >
              <div className="w-100">
                <p className="ant-upload-drag-icon ">
                  <FiUploadCloud size={24} />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Only jpg,png,pdf,doc files accepted.
                </p>
              </div>
            </Dragger>
          </Form.Item>
        </div>
        {isProviderReffer && isProviderReffer === true
          ? (data?.attachmentlink ?? [])?.length > 0 && (
              <div>
                <div className="fs-14 fw-500 lh-20 mb-1">
                  Recent Attachments
                </div>

                <Row>
                  {(data?.attachmentlink ?? [])?.map((el) => (
                    <Col sm={12} span={24}>
                      <div className="px-2">
                        <AttachmentCard
                          onSelect={() => handleAttachmentSelect(el?.id)}
                          isSelected={selected?.includes(el?.id) ?? false}
                          url={el?.image}
                          prescriptionId={el?.prescriptionId}
                          onPreview={() => onPreview(el)}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            )
          : (data?.pharmacyinfo?.prescription_image ?? [])?.length > 0 && (
              <div>
                <div className="fs-14 fw-500 lh-20 mb-1">
                  Recent Attachments
                </div>

                <Row>
                  {(data?.pharmacyinfo?.prescription_image ?? [])?.map((el) => (
                    <Col sm={12} span={24}>
                      <div className="px-2">
                        <AttachmentCard
                          onSelect={() => handleAttachmentSelect(el?.id)}
                          isSelected={selected?.includes(el?.id) ?? false}
                          url={el?.image}
                          prescriptionId={el?.prescriptionId}
                          onPreview={() => onPreview(el)}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            )}




            { isProviderReffer && isProviderReffer === true ?null:data?.accept_status === "2" || data?.accept_status === "5"?<Form.Item
            name="vendorid"
            label="Update Partner"
            style={{ maxWidth: 570 }}
          >
            <Select
              className="select-h-46 mb-3"
              allowClear={false}
              showSearch
              options={(vendors ?? []).map((el) => ({
                ...el,
                label: `${el?.OrganizationName} ${el?.insurancetag === 0?"(Insurance Not Matched)":"(Insurance  Matched)"}`,
                value: el?.partnerId,
              }))}
            />
          </Form.Item> :null}
      </Form>
      {previewOpen && previewImage && (
        <AttachmentPreview data={previewImage} onClose={handleCancel} />
      )}
    </Drawer>
  );
};

UpdateOrder.propTypes = {};
export default UpdateOrder;