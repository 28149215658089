import React, { useEffect, useState } from "react";
import { CaseMangerWrapper } from "../../../components/Wrapper";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { APIHitCall } from "../../../features/case-manager/caseManagerService";
import { useDispatch, useSelector } from "react-redux";
import DonutChart from "../../../components/charts/DonutChart";
import AreaChart from "../../../components/charts/AreaCharts";
import Chart from "react-apexcharts";
import { Select } from "antd";
import { OrderStatusChart } from "../../../components/charts/OrderStatusChart";
import InsuranceCountChart from "../../../components/charts/InsuranceCountChart";
const { Option } = Select;

const ChartComponent = () => {
  const { user } = useSelector(({ user }) => ({ ...user }));
  const [completedCategoriesData, setCompletedCategoriesData] = useState([]);
  const [completedSeriesData, setCompletedSeriesData] = useState([]);
  const [createdCategoriesData, setCreatedCategoriesData] = useState([]);
  const [createdSeriesData, setCreatedSeriesData] = useState([]);
  const [orderByCategoryLabel, setOrderByCategoryLabel] = useState([]);
  const [orderByCategorySeriesData, setOrderByCategorySeriesData] = useState([]);
  const [orderByProviderLabel, setOrderByProviderLabel] = useState([]);
  const [orderByProviderSeriesData, setOrderByProviderSeriesData] = useState([]);
  const [orderByPartnerLabel, setOrderByPartnerLabel] = useState([]);
  const [orderByPartnerSeriesData, setOrderByPartnerSeriesData] = useState([]);
  const [orderStatusData, setOrderStatusData] = useState({ dates: [], pending: [], accept: [], reject: [] });
  const [timeInterval, setTimeInterval] = useState("daily");

  useEffect(() => {
    fetchCompletedOrderData();
  }, [timeInterval]);

  useEffect(() => {
    get_analytics_order_by_category();
  }, []);

  useEffect(() => {
    get_order_by_provider();
  }, []);

  useEffect(() => {
    get_order_by_partner();
  }, []);

  useEffect(() => {
    fetchOrderStatusData();
  }, [timeInterval]);

  async function fetchOrderStatusData() {
    try {
      const formData = new FormData();
      formData.append("action", "analytics_order_accpet_reject");
      formData.append("clinicid", user?.society_id);
      formData.append("auterizetokenid", user?.id);
      const response = await APIHitCall(formData);
      if (response?.data?.status === 200) {
        const result = response.data.result;
        const data = Object.keys(result).map(date => ({
          schedule_date: date,
          pending: Number(result[date].pending),
          accept: Number(result[date].accept),
          reject: Number(result[date].reject),
        }));
        const newChartData = processOrderStatusData(data, timeInterval);
        setOrderStatusData(newChartData);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).format(date);
  }
  function processOrderStatusData(data, interval) {
    const groupedData = {};
    data.forEach(item => {
      const date = new Date(item.schedule_date);
      let key;
      if (interval === "weekly") {
        key = `${date.getFullYear()}-W${getWeekNumber(date)}`;
      } else if (interval === "monthly") {
        key = `${date.getFullYear()}-${date.getMonth() + 1}`;
      } else {
        key = formatDate(item.schedule_date); // Format the date here
      }
      if (!groupedData[key]) {
        groupedData[key] = { pending: 0, accept: 0, reject: 0 };
      }
      groupedData[key].pending += item.pending;
      groupedData[key].accept += item.accept;
      groupedData[key].reject += item.reject;
    });
  
    const dates = Object.keys(groupedData);
    const pending = dates.map(date => groupedData[date].pending);
    const accept = dates.map(date => groupedData[date].accept);
    const reject = dates.map(date => groupedData[date].reject);
    return { dates, pending, accept, reject };
  }
  
  function getWeekNumber(date) {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
    return Math.ceil((date.getDay() + 1 + days) / 7);
  }

  async function fetchCompletedOrderData() {
    try {
      const formData = new FormData();
      formData.append("action", "analytics_order_by_date");
      formData.append("casemanaderid", user?.id);
      formData.append("clinicid", user?.society_id);
      formData.append("auterizetokenid", user?.id);
      const response = await APIHitCall(formData);
      if (response?.data?.status === 200) {
        if (response.data.completed.length) {
          const newChartData = processChartData(response.data.completed, timeInterval);
          setCompletedCategoriesData(newChartData.dates);
          setCompletedSeriesData(newChartData.counts);
        }
          
        if (response.data.created.length) {
          const newChartData = processChartData(response.data.created, timeInterval);
          setCreatedCategoriesData(newChartData.dates);
          setCreatedSeriesData(newChartData.counts);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function processChartData(data, interval) {
    const groupedData = {};
    data.forEach(item => {
      const date = new Date(item.schedule_date);
      let key;
      if (interval === "weekly") {
        key = `${date.getFullYear()}-W${getWeekNumber(date)}`;
      } else if (interval === "monthly") {
        key = `${date.getFullYear()}-${date.getMonth() + 1}`;
      } else {
        key = formatDate(item.schedule_date); // Format the date here
      }
      if (!groupedData[key]) {
        groupedData[key] = 0;
      }
      groupedData[key] += Number(item.totalcompletecount || item.totalcount);
    });
  
    const dates = Object.keys(groupedData);
    const counts = Object.values(groupedData);
    return { dates, counts };
  }

  async function get_analytics_order_by_category() {
    try {
      const FD = new FormData();
      FD.append("action", "analytics_order_by_category");
      FD.append("casemanaderid", user?.id);
      FD.append("clinicid", user?.society_id);
      FD.append("auterizetokenid", user?.id);
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        const newChartData = res.data.result;
        const labels = newChartData.map((item) => item.name);
        const counts = newChartData.map((item) => Number(item.catgeory_count));
        setOrderByCategoryLabel(labels);
        setOrderByCategorySeriesData(counts);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function get_order_by_provider() {
    try {
      const formData = new FormData();
      formData.append("action", "analytics_order_by_provider");
      formData.append("casemanaderid", user?.id);
      formData.append("auterizetokenid", user?.id);
      formData.append("clinicid", user?.society_id);
      const response = await APIHitCall(formData);
      if (response?.data?.status === 200) {
        const newChartData = response.data.result;
        const labels = newChartData.map((item) => item.name);
        const counts = newChartData.map((item) => Number(item.clinicordercount));
        setOrderByProviderLabel(labels);
        setOrderByProviderSeriesData(counts);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function get_order_by_partner() {
    try {
      const formData = new FormData();
      formData.append("action", "analytics_order_by_partner");
      formData.append("casemanaderid", user?.id);
      formData.append("auterizetokenid", user?.id);
      formData.append("clinicid", user?.society_id);
      const response = await APIHitCall(formData);
      if (response?.data?.status === 200) {
        const newChartData = response.data.result;
        const labels = newChartData.map((item) => item.partner);
        const counts = newChartData.map((item) => Number(item.partnerordercount));
        setOrderByPartnerLabel(labels);
        setOrderByPartnerSeriesData(counts);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div style={styles.main}>
      <CaseMangerWrapper>
        <div className="div" style={{ margin: "10px 20px 10px 20px" }}>
          <div style={{ margin: "10px 0" }}>
            <Select
              value={timeInterval}
              onChange={setTimeInterval}
              style={{ width: 200 }}
              placeholder="Select Time Interval"
            >
              <Option value="daily">Daily</Option>
              <Option value="weekly">Weekly</Option>
              <Option value="monthly">Monthly</Option>
            </Select>
          </div>

          <AreaChart
            title={"Completed Orders"}
            categoriesData={completedCategoriesData}
            seriesData={completedSeriesData}
            baseColor={'#50CD89'}
            lightColor={'#E8FFF3'}
          />

          <DonutChart
            title={"Order By Category"}
            labels={orderByCategoryLabel}
            seriesData={orderByCategorySeriesData}
          />

          <DonutChart
            title={"Order By Provider"}
            labels={orderByProviderLabel}
            seriesData={orderByProviderSeriesData}
          />

          <DonutChart
            title={"Order By Partner"}
            labels={orderByPartnerLabel}
            seriesData={orderByPartnerSeriesData}
          />

          <AreaChart
            title={"Created Orders"}
            categoriesData={createdCategoriesData}
            seriesData={createdSeriesData}
            baseColor={'#FDC700'}
            lightColor={'#FFF8DD'}
          />

          <OrderStatusChart data={orderStatusData} />

          <InsuranceCountChart/>

          <div style={{height: '20px'}} />
        </div>
      </CaseMangerWrapper>
    </div>
  );
};



export default ChartComponent;