import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { patientService } from "./services";

const initialState = {
  patientDetail: null,
  pastOrders: {
    loading: true,
    data: [],
    count: 0,
    moreLoading: false,
  },
  upcomingOrders: {
    loading: true,
    data: [],
    count: 0,
    moreLoading: false,
  },
  mainActiveTab: "personal-Information",
};

export const fetchAppointments = createAsyncThunk(
  "fetchServiceList",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    const { user, patient } = getState();

    const formData = new FormData();
    Object.keys(payload).forEach((i) => {
      formData.append(i, payload[i]);
    });
    formData.append("action", "inprocessTaskcasemanager");
    formData.append("auterizetokenid", user?.user?.id);
    formData.append("casemanager", user?.user?.id);
    // formData.append("page_start", 0);
    const response = await patientService(formData, user?.token);
    return {
      status: response?.data?.status || 400,
      listType: payload?.jobstatus,
      data:
        payload?.page_start > 0
          ? [
              ...(payload?.jobstatus === 4
                ? patient?.upcomingOrders?.data ?? []
                : patient?.pastOrders?.data ?? []),
              ...(response?.data?.result ?? []),
            ]
          : response?.data?.result ?? [],
      count: response?.data?.count || 0,
    };
  }
);

export const patientSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setMainPatientTab: (state, action) => {
      state.mainActiveTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppointments.pending, (state, { meta }) => {
        state.pastOrders.loading = meta?.arg?.page_start === 0 ? true : false;
        state.upcomingOrders.loading =
          meta?.arg?.page_start === 0 ? true : false;
        state.pastOrders.moreLoading = meta?.arg?.page_start > 0 ? true : false;
        state.upcomingOrders.moreLoading =
          meta?.arg?.page_start > 0 ? true : false;
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          if (action.payload.listType === "7") {
            state.pastOrders = {
              data: action?.payload?.data ?? [],
              count: action?.payload?.count || 0,
              loading: false,
              moreLoading: false,
            };
          } else {
            state.upcomingOrders = {
              data: action?.payload?.data ?? [],
              count: action?.payload?.count || 0,
              loading: false,
            };
          }
        } else {
          state.pastOrders = {
            data: [],
            loading: false,
          };
          state.upcomingOrders = {
            data: [],
            loading: false,
          };
        }
      });
  },
});

export const { setMainPatientTab } = patientSlice.actions;

export default patientSlice.reducer;
