import React, {  useEffect,  useState } from "react";
import {  useSelector } from "react-redux";

import Lottie  from "lottie-react";
import lottieload from "../../../../src/assets/images/lottieload.json";

import {
 
  Modal,

  
  Spin,

} from "antd";

import { apihitCall } from "../../../service/authService";
import NotesChat from "./NotesChat";

const axios = require('axios');
export const AiSummaryModal = ({ isVisible, onClose, patientData }) => {
    const [summary, setSummary] = useState("");
    const [pdfText, setPdfText] = useState("");
    const [loading, setLoading] = useState(false);
    const [loading_all, setLoadingAll] = useState(true);
    const [loading_all_api, setLoadingAllApi] = useState(true);
    const [count, setCount] = useState(0);
  
    const [validTabs, setValidTabs] = useState([]);
    const [activeTab, setActiveTab] = useState("");
    const [chat, setChat] = useState(null);
    
    const { user } = useSelector((state) => state.user);
    const { token } = useSelector(({ user }) => user);
     
    const menuItemsAll = [
      { key: "history", label: "History and Physical Summary", doc_type_index: 0 },
      { key: "progress", label: "Progress Summary", doc_type_index: 1 },
      { key: "physicalTherapy", label: "Physical Therapy Summary", doc_type_index: 2 },
      { key: "rehabilitation", label: "Rehabilitation Therapy Summary", doc_type_index: 3 },
      { key: "occupational", label: "Occupational Therapy Summary", doc_type_index: 4 },
      { key: "speech", label: "Speech Therapy Summary", doc_type_index: 5 },
      { key: "consultation", label: "Consultation Request Summary", doc_type_index: 6 },
      { key: "treatment", label: "Treatment Plan Summary", doc_type_index: 7 },
      { key: "goals", label: "Goals Summary", doc_type_index: 8 },
    ];
  
    useEffect(() => {
      if (patientData) {
          const controller = new AbortController();
          setAbortController(controller); // Save the controller for possible external use
          fetchValidTabs(controller); // Pass the controller to avoid timing issues
          return () => {
              controller.abort(); // Cancel all ongoing requests on unmount
              console.log("All requests aborted.");
          };
      }
  }, [patientData]);
  
  
   
    const [abortController, setAbortController] = useState(null);
  
    const fetchValidTabs = async (controller) => {
    
      setLoadingAll(true)
      setLoadingAllApi(true)
      try{
        const validationPromises =  menuItemsAll.map(async (item,index) => {
        
        try {
          const isValid = await callApiWithDocTypeIndex(item.doc_type_index,controller);
           
          if (isValid) {
            setLoadingAll(false)
            setValidTabs((prevValidTabs) => {
              const updatedTabs = [...prevValidTabs, item.key];
              // Set the active tab to the first valid tab if it's not already set
              if (!activeTab) {
                setActiveTab(updatedTabs[0]);
              }
              return updatedTabs;
            });
          }
        } catch (error) {
          console.error(`Error validating tab ${item.key}:`, error);
        }
      });
      await Promise.all(validationPromises);
  
    }
      catch (error) {
        console.error("Error fetching valid tabs:", error);
      } finally {
        console.log("finally")
        setLoadingAll(false); // Ensure loading is stopped regardless of success or failure
      }
     
    };
  
    useEffect(()=>{
     
      if(count === menuItemsAll.length){
        console.log(`${count} ${menuItemsAll.length}`)
        setLoadingAllApi(false)
      }else{
        console.log(`else ${count} ${menuItemsAll.length} `)
  
      }
    },[count])
        
    const callApiWithDocTypeIndex = async (doc_type_index,controller) => {
    //  const apiUrl = 'http://127.0.0.1:8080/process_patient_data_custom';
     const apiUrl = 'https://aisummary.medsetgo.com/process_patient_data_custom';
       
      const params = {
        bypass: 0,
        isSingle: 1,
        clinic: user?.society_id,
        patient: patientData.patientid,
        doc_type_index: doc_type_index
      };
  
      try {
        const response = await axios.get(apiUrl, { params , signal: controller.signal});
        setCount((prevCount) => prevCount + 1);
  
        
       
    
  
        return response.data.status == "201" || response.data.status == "200";
      } catch (error) {
        console.error('Error calling API:', error.message);
        return false;
      }
    };
  
    const fetchTabData = async (tab) => {
      let formData = new FormData();
      formData.append("patientid", patientData.patientid);
      formData.append("clinicid", user?.society_id);
      formData.append("auterizetokenid", user?.id);
      formData.append("patientId", patientData.patientid);

  
      switch (tab) {
        case "history":
        case "progress":
          formData.append("action", "get_patient_summary_text");
          formData.append("summaryname", tab === "history" ? "HP" : "PROG NOTE");
          break;
        case "consultation":
          formData.append("action", "get_patient_consultation_request_notes_text");
          break;
        case "treatment":
          formData.append("action", "get_patient_treatment_plan_text");
          break;
        case "goals":
          formData.append("action", "get_patient_goals_text");
          break;
        case "physicalTherapy":
        case "rehabilitation":
        case "occupational":
        case "speech":
          formData.append("action", "get_patient_ptot_note_text");
          formData.append("note_type", getNoteType(tab));
          break;
        default:
          break;
      }
  
      try {
        const res = await apihitCall(formData, token);
        const hasData = res?.data?.status === 200 && res?.data?.result?.summary_text;
        return { key: tab, hasData, summary_text: res?.data?.result?.summary_text,pdf_text: res?.data?.result?.pdf_text};
      } catch (error) {
        console.error(`Error fetching data for tab ${tab}:`, error);
        return { key: tab, hasData: false };
      }
    };
  
    const getNoteType = (tab) => {
      switch (tab) {
        case "physicalTherapy":
          return "1";
        case "rehabilitation":
          return "2";
        case "occupational":
          return "3";
        case "speech":
          return "4";
        default:
          return "";
      }
    };
  
    const loadSummaryForTab = async (tab) => {
      setLoading(true);
      const result = await fetchTabData(tab);
      setSummary(result.hasData ? result.summary_text || "" : "");
      
      
      setPdfText(result.hasData ? result.pdf_text || null : null);

      setLoading(false);
    };
  
    useEffect(() => {
      if (activeTab) {
        loadSummaryForTab(activeTab);
      }
    }, [activeTab]);
  
    return (
      <Modal
        visible={isVisible}
        onCancel={onClose}
        width="100vw"
        footer={null}
        bodyStyle={{ padding: 0, height: "90vh" }}
        style={{ top: 5 }}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            <span
              onClick={onClose}
              style={{
                fontSize: "24px",
                cursor: "pointer",
                marginRight: "auto",
              }}
            >
              ✖
            </span>
          </div>
        }
        closeIcon={
          <span
            style={{
              fontSize: "24px",
              position: "absolute",
              left: 0,
              top: 10,
              cursor: "pointer",
            }}
          >
            
          </span>
        }
      >
        {loading_all?<LoadingScreen/>
  :<div className="container-fluid h-100 d-flex flex-row" >
          {/* Sidebar */}
          {summary && (
            <div className="col-3 border-end p-3 bg-white">
              <nav>
            
                  
                  
                {menuItemsAll
                  .filter((item) => validTabs.includes(item.key))
                  .map((item) => (
                    <div
                      key={item.key}
                      onClick={() => setActiveTab(item.key)}
                      className={`mb-3 ${activeTab === item.key ? "text-primary fw-bold" : "text-secondary"}`}
                      style={{ cursor: "pointer", display: "flex" }}
                    >
                      <img
                        src="../../../assets/images/ai.png"
                        alt="AI Icon"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                          filter: "invert(34%) sepia(72%) saturate(636%) hue-rotate(196deg) brightness(95%) contrast(86%)",
                        }}
                      />
                      {item.label}
                    </div>
                  ))}
                   {loading_all_api == true ? <div
                     
                      className={`mb-3 ${ "text-secondary"}`}
                      style={{ cursor: "pointer", display: "flex" }}
                    >
                      <Spin
                       
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                          fontWeight:"bold"
                        }}
                      />
                      <div style={{
                         
                          fontWeight:"bold"
                        }} >
                      Generating more information
                      </div>
                    </div>:null}
              </nav>
            </div>
          )}
         
          <div className="col-9 p-3">
            <h6 className="fw-bold mb-3">AI Summary</h6>
            <div
              className="summary-container"
              style={{
                maxHeight: "calc(100vh - 120px)",
                overflowY: "auto",
              }}
            >
              {loading ? (
                <Spin />
              ) : summary ? (
                <div>
                    <div
                  dangerouslySetInnerHTML={{ __html: decodeHtml(summary) }}
                  className="text-dark"
                />
               {/* <button 
    onClick={() => {
        setChat(pdfText);
        console.log(pdfText);
    }} 
    style={{
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        width: '60px',
        height: '60px',
        backgroundColor: '#4C6BF5',
        color: 'white',
        fontSize: '10px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        border: 'none',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
    }}
    onMouseEnter={(e) => {
        e.target.style.transform = 'scale(1.1)';
        e.target.style.backgroundColor = '#3b56d9';
    }}
    onMouseLeave={(e) => {
        e.target.style.transform = 'scale(1)';
        e.target.style.backgroundColor = '#4C6BF5';
    }}
>
<img
                        src="../../../assets/images/ai.png"
                        alt="AI Icon"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />Ask ?
</button>  */}

                {chat != null ?
                  
                    <NotesChat activeTab={activeTab} setChat={setChat} msg={`You are MedsetGo Assistant, a virtual assistant designed to help doctors extract meaningful insights from patient data. Your primary task is to interpret and summarize patient information accurately. user will ask you questions you need to answer from this ${pdfText}`}/>
               :null}
                </div>
                
              ) : (
                <div className="text-muted text-center">
                  <p>No notes are available for this patient at the moment.</p>
                </div>
              )}
            </div>
          </div>
        </div>}
      </Modal>
    );
  };
  
  const decodeHtml = (escapedString) => {
    return escapedString.replace(/^```html\s*/, '').replace(/```$/, '');
  };
  
  
  const LoadingScreen = () => {
    const [messageIndex, setMessageIndex] = useState(0);
    const messages = [
      "Processing patient notes...",
      "Analyzing data for insights...",
      "Crafting a detailed summary...",
      "Just a moment, your insights are getting ready...",
    ];
  
    useEffect(() => {
      const interval = setInterval(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      }, 12000); // Change message every 3 seconds
      return () => clearInterval(interval); // Cleanup on unmount
    }, []);
  
    return (
      <div
        className="container-fluid h-100 d-flex"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f2f4f8",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {/* Animated Lottie Component */}
        <Lottie
          animationData={lottieload}
          style={{ width: "300px", height: "300px", marginBottom: "20px" }}
        />
  
        {/* Rotating Messages */}
        <div style={{ fontSize: "18px", marginBottom: "15px" }}>
          {messages[messageIndex]}
        </div>
  
        {/* Progress Indicator */}
        <div
          style={{
            width: "60%",
            height: "8px",
            backgroundColor: "#d9d9d9",
            borderRadius: "4px",
            overflow: "hidden",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#4caf50",
              animation: "progressAnimation 32s infinite",
            }}
          ></div>
        </div>
  
        {/* Additional Note */}
        <div style={{ fontSize: "14px", color: "#6c757d" }}>
          Please hold on while we craft insights for you.
        </div>
  
        {/* Keyframe Animation */}
        <style>
      
    {`
      @keyframes progressAnimation {
        0% { transform: translateX(-100%); }
        10% { transform: translateX(-90%); }
        20% { transform: translateX(-80%); }
              30% { transform: translateX(-70%); }
        40% { transform: translateX(-60%); }
  
        50% { transform: translateX(-50%); }
              60% { transform: translateX(-40%); }
        70% { transform: translateX(-30%); }
        80% { transform: translateX(-20%); }
        90% { transform: translateX(-10%); }
  
        100% { transform: translateX(0%); }
      }
  
   
    `}
  </style>
       
      </div>
    );
  };