import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { APIHitCall } from '../../features/case-manager/caseManagerService';
import { useSelector } from 'react-redux';
import { PieChartOutlined, ClusterOutlined } from "@ant-design/icons"; // Ant Design icons
// Alternatively, you can use react-icons
 import { FaChartPie, FaSitemap } from "react-icons/fa";

const InsuranceCountChart = () => {
    const [showPieChart, setShowPieChart] = useState(true);
  
    const toggleChart = () => {
      setShowPieChart(!showPieChart);
    };
  
    return (
      <div
        style={{
          boxShadow: "0px 10px 15px #dddddd",
          margin: "20px 10px",
          padding: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "3px 15px",
          }}
        >
          <span style={{ fontWeight: "500", fontSize: "20px" }}>
            {"Insurance Usage by Vendor"}
          </span>
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={toggleChart}
          >
            <div
  onClick={toggleChart}
  style={{
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#1890ff",
    fontWeight: "500",
    gap: "8px", // Adds space between the text and the icon
    fontSize: "16px", // Consistent text size
  }}
>
  <span>Switch Chart</span>
  {showPieChart ? (
    <ClusterOutlined style={{ fontSize: "20px" }} />
  ) : (
    <PieChartOutlined style={{ fontSize: "20px" }} />
  )}
</div>

           
          </div>
        </div>
        {showPieChart ? <InsuranceCountChartPie /> : <InsuranceCountChartTree />}
      </div>
    );
  };



const InsuranceCountChartPie = () => {
  const [chartData, setChartData] = useState({ labels: [], series: [] });
  const { user } = useSelector(({ user }) => ({ ...user }));

  useEffect(() => {
    fetchInsuranceData();
  }, []);

  const fetchInsuranceData = async () => {
    try {
      const formData = createFormData();
      const response = await APIHitCall(formData);

      if (response?.data?.status === 200) {
        const formattedData = formatChartData(response.data.result);
        setChartData(formattedData);
      }
    } catch (error) {
      console.error('Error fetching insurance data:', error);
    }
  };

  const createFormData = () => {
    const formData = new FormData();
    formData.append('action', 'analytics_order_provider');
    formData.append("clinicid", user?.society_id);
    formData.append("auterizetokenid", user?.id);
    return formData;
  };

  const formatChartData = (data) => {
    const labels = [];
    const series = [];

    Object.entries(data).forEach(([vendorId, vendor]) => {
      const { vendorname, ...insurances } = vendor;
      const insuranceDetails = [];
      let totalInsuranceCount = 0;

      Object.entries(insurances).forEach(([insuranceId, insurance]) => {
        totalInsuranceCount += insurance.insurancecount;
        insuranceDetails.push(`<div style="font-weight: bold;">${insurance.insurancename} (${insurance.insurancecount})</div>`);
    });

      labels.push(`${vendorname}: ${insuranceDetails.join('')}`);
      series.push(totalInsuranceCount);
    });

    return { labels, series };
  };

  const chartOptions = {
    chart: {
      type: 'pie',
    },
    labels: chartData.labels,
  };

  return (
    <div>
     
      <Chart options={chartOptions} series={chartData.series} type="pie" width="60%" />
    </div>
  );
};




const InsuranceCountChartTree = () => {
  const [chartData, setChartData] = useState([]);
  const { user } = useSelector(({ user }) => ({ ...user }));

  useEffect(() => {
    fetchInsuranceData();
  }, []);

  const fetchInsuranceData = async () => {
    try {
      const formData = createFormData();
      const response = await APIHitCall(formData);

      if (response?.data?.status === 200) {
        const formattedData = formatChartData(response.data.result);
        setChartData(formattedData);
      }
    } catch (error) {
      console.error('Error fetching insurance data:', error);
    }
  };

  const createFormData = () => {
    const formData = new FormData();
    formData.append('action', 'analytics_order_provider');
    formData.append("clinicid", user?.society_id);
    formData.append("auterizetokenid", user?.id);
    return formData;
  };

  const formatChartData = (data) => {
    const series = [];

    Object.entries(data).forEach(([vendorId, vendor]) => {
      const { vendorname, ...insurances } = vendor;
      const vendorData = {
        name: vendorname,
        data: [],
      };

      Object.entries(insurances).forEach(([insuranceId, insurance]) => {
        vendorData.data.push({
          x: insurance.insurancename,
          y: insurance.insurancecount,
        });
      });

      series.push(vendorData);
    });

    return series;
  };

  const chartOptions = {
    chart: {
      type: 'treemap',
    },
    legend: {
      show: true,
    },
  
    tooltip: {
      y: {
        formatter: (value) => `${value}`,
      },
    },
  };

  return (
    <div
   
  >
    {<Chart options={chartOptions} series={chartData} type="treemap"  width={"60%"}/>}
  </div>
  
  
  );
};

export default InsuranceCountChart;
